import React, { useContext, useEffect, useState } from 'react';
import { apiFetch, downloadFile } from './API';
import { ChatPrint, Comment } from './Chat';
import { FunnelChart, LGChart } from './drawGraph';
import { MessagesContext } from './MessagesContext';

import '../css/LG.css'
  
export const ChatTimeSentiments = ({roomId, isResolve, setResolveCnt}) => {
    const {messages} = useContext(MessagesContext)

    return (
        <div className="sub active" id="TimeSentiments">
            <div className="sentimentSub sentimentUl" id="TS-1">
                <div className="textLeft">닉네임</div>
                <div className="textLeft">문의 내용</div>
                <div className="textRight">시간</div>   
                <div className="textCenter"><Comment cmt="negative"/>부정도</div>
                {/*<div className="textRight">답변</div>*/}
            </div>
            <ChatPrint roomId={roomId} messages={messages} isSort={1} isResolve={isResolve} setResolveCnt={setResolveCnt} categoryType={0}/>
        </div>
    )
}

export const ChatNegativeSentiments = ({roomId, isResolve, setResolveCnt}) => {
    const {messages} = useContext(MessagesContext)
    
    return (
        <div className="sub active" id="NegativeSentiments">
            <div className="sentimentSub sentimentUl" id="NS-1">
                <div className="textLeft">닉네임</div>
                <div className="textLeft">문의 내용</div>
                <div className="textRight">시간</div>
                <div className="textCenter"><Comment cmt="negative"/>부정도</div>
                {/*<div className="textRight">답변</div>*/}
            </div>
            <ChatPrint roomId={roomId} messages={messages} isSort={0} isResolve={isResolve} setResolveCnt={setResolveCnt} categoryType={0}/>
        </div>
    )
}

export const ChatFunnel = ({roomId, isResolve}) => {
    const {messages} = useContext(MessagesContext)

    const [msg, setMsg] = useState()
    const [graph, setGraph] = useState('freq')
    const [stage, setStage] = useState(0)
    const [currentTime, setCurrentTime] = useState(new Date());

    const [funnel, setFunnel] = useState([0,0,0,0,0,0])

    useEffect(() => {
        async function data() {
            const newList = await apiFetch(`/${roomId}/funnel?graph=${graph}&stage=${stage}`)
            if(funnel != newList.graph) {
                setFunnel(newList.graph)
            }
            setMsg(newList.chats)
        }

        // 1초마다 시간을 업데이트하는 interval 설정
        const intervalId = setInterval(() => {
        setCurrentTime(new Date());
        }, 1000);
        data()

        // 컴포넌트가 언마운트될 때 interval을 정리
        return () => clearInterval(intervalId);
    }, []);

    useEffect(()=>{
        async function data(cnt) {
            const newList = await apiFetch(`/${roomId}/funnel?graph=${graph}&stage=${stage}`)
            if(newList.chats.length > 0 && newList.chats[0].confirm_msg == '' && cnt < 1) {
                //console.log(cnt)
                data(cnt+1)
            } else {
                if(funnel != newList.graph) {
                    setFunnel(newList.graph)
                }
                setMsg(newList.chats)
            }
        }
        data(0)
    },[messages])

    const setDrawGraph = async (newStage, newGraph) => {
        const newList = await apiFetch(`/${roomId}/funnel?graph=${newGraph}&stage=${newStage}`)
        setStage(newStage)
        setGraph(newGraph)
        if(funnel != newList.graph) {
            setFunnel(newList.graph)
        }
        setMsg(newList.chats)
    }

    const setSentimentColor = (sentiment) => {
        if (sentiment > 1) {
            return 'black'
        } else if(sentiment > 0.66) {
            return '#FF0000'
        } else if( sentiment > 0.33) {
            return '#F08A2C'
        } else if(sentiment > 0) {
            return '#008000'
        }
    }

    const setNegativeName = (num) => {
        if(num > 1) {
            return '-'
        } else if(num > 0.66) {
            return '나쁨'
        } else if (num > 0.33) {
            return '중립'
        } else if (num > 0) {
            return '좋음'
        }
    }

    return (
        <div className="sub active" id="funnel">
            <div className="chatEtcMenu">
                <div className="textLeft">
                    <div className='chatEtcMenuTitle'>그래프</div>
                </div>
                <div className="textLeft">
                    <div className='chatEtcMenuContent'>
                        <input className='selected' type="radio" id="graphFreq" name="graphFreq" checked={graph=='freq'}/>
                        <label for="graphFreq" className='chatEtcMenuButton' onClick={()=>{setDrawGraph(stage, 'freq')}}>채팅 빈도</label>
                        <input className='selected' type="radio" id="graphSentiment" name="graphSentiment" checked={graph=='sentiment'}/>
                        <label for="graphSentiment" className='chatEtcMenuButton' onClick={()=>{setDrawGraph(stage, 'sentiment')}}>센티먼트</label>
                    </div>
                </div>
                <FunnelChart funnel={funnel}/>
                <div className="textLeft">
                    <div className='chatEtcMenuTitle'><Comment cmt="funnel"/>단계</div>
                </div>
                <div className="textLeft">
                    <div className='chatEtcMenuContent chatEtcMenuClick'>
                        <input className='selected' type="radio" id="F0" name="F0" checked={stage == 0}/>
                        <label for="F0" className='chatEtcMenuButton' onClick={()=>{setDrawGraph(0, graph)}}>제품 인식</label>
                        <div className="arrow">→</div>
                        <input className='selected' type="radio" id="F1" name="F1" checked={stage == 1}/>
                        <label for="F1" className='chatEtcMenuButton' onClick={()=>{setDrawGraph(1, graph)}}>제품 이해</label>
                        <div className="arrow">→</div>
                        <input className='selected' type="radio" id="F2" name="F2" checked={stage == 2}/>
                        <label for="F2" className='chatEtcMenuButton' onClick={()=>{setDrawGraph(2, graph)}}>가격·혜택</label>
                        <div className="arrow">→</div>
                        <input className='selected' type="radio" id="F3" name="F3" checked={stage == 3}/>
                        <label for="F3" className='chatEtcMenuButton' onClick={()=>{setDrawGraph(3, graph)}}>배송</label>
                        <div className="arrow">→</div>
                        <input className='selected' type="radio" id="F4" name="F4" checked={stage == 4}/>
                        <label for="F4" className='chatEtcMenuButton' onClick={()=>{setDrawGraph(4, graph)}}>결제·환불</label>
                        <div className="arrow">→</div>
                        <input className='selected' type="radio" id="F5" name="F5" checked={stage == 5}/>
                        <label for="F5" className='chatEtcMenuButton' onClick={()=>{setDrawGraph(5, graph)}}>피드백</label>
                    </div>
                </div>
                <div className="textLeft">
                    <div className='chatEtcMenuTitle'>{graph== 'freq' ? '채팅 빈도' : '센티먼트'}</div>
                </div>
                <div className="textLeft">
                    <div className='chatEtcMenuContent sentimentTitleS'>
                        {funnel != undefined && funnel.map((num)=>{
                            return graph == 'freq' ? <div className="funnelFreq">{num.toFixed(0)}</div> : <div className="funnelFreq" style={{color: setSentimentColor(1-num)}}>{setNegativeName(1-num)}</div>
                        })}
                    </div>
                </div>
                <div className="sentimentSub sentimentUl" id="NS-1" style={{margin: 0, gridColumn: '1/3'}}>
                    <div className="textLeft">닉네임</div>
                    <div className="textLeft">문의 내용</div>
                    <div className="textRight">시간</div>
                    <div className="textCenter"><Comment cmt="negative"/>부정도</div>
                    {/*<div className="textRight">답변</div>*/}
                </div>
            </div>
            <ChatPrint roomId={roomId} messages={msg} isSort={2} isResolve={isResolve} categoryType={0}/>
        </div>
    )
}

export const ChatLG = ({roomId, isResolve}) => {
    const {messages} = useContext(MessagesContext)

    const [msg, setMsg] = useState()
    const [stage, setStage] = useState(0)
    const [currentTime, setCurrentTime] = useState(new Date());

    const [funnel, setFunnel] = useState([0,0,0,0,0,0,0,0])

    useEffect(() => {
        async function data() {
            const newList = await apiFetch(`/${roomId}/lg-category?stage=${stage}`)
            if(funnel != newList.graph) {
                setFunnel(newList.graph)
            }
            setMsg(newList.chats)
        }

        // 1초마다 시간을 업데이트하는 interval 설정
        const intervalId = setInterval(() => {
        setCurrentTime(new Date());
        }, 1000);
        data()

        // 컴포넌트가 언마운트될 때 interval을 정리
        return () => clearInterval(intervalId);
    }, []);

    useEffect(()=>{
        async function data(cnt) {
            const newList = await apiFetch(`/${roomId}/lg-category?stage=${stage}`)
            if(newList.chats != [] && newList.chats[0].confirm_msg == '' && cnt < 1) {
                //console.log(cnt)
                data(cnt+1)
            } else {
                if(funnel != newList.graph) {
                    setFunnel(newList.graph)
                }
                setMsg(newList.chats)
            }
        }
        data(0)
    },[messages])

    const setDrawGraph = async (newStage) => {
        const newList = await apiFetch(`/${roomId}/lg-category?stage=${newStage}`)
        setStage(newStage)
        if(funnel != newList.graph) {
            setFunnel(newList.graph)
        }
        setMsg(newList.chats)
    }

    const download = async () =>{
        await downloadFile(`/${roomId}/lg-category/download`)
    }

    return (
        <>
        <div className="sub active" id="LG">
            <div className="chatEtcMenu">
                <LGChart funnel={funnel}/>
                <div className="textLeft chatEtcMenuSelectBar">
                    <div className='chatEtcMenuContent chatEtcMenuClick'>
                        <input className='selected' type="radio" id="F0" name="F0" checked={stage == 0}/>
                        <label for="F0" className='chatEtcMenuButton' onClick={()=>{setDrawGraph(0)}} style={{backgroundColor: '#ECF1E0'}}>단말</label>
                        
                        <input className='selected' type="radio" id="F1" name="F1" checked={stage == 1}/>
                        <label for="F1" className='chatEtcMenuButton' onClick={()=>{setDrawGraph(1)}} style={{backgroundColor: '#DEE6F0'}}>기능(성능)</label>
                        
                        <input className='selected' type="radio" id="F2" name="F2" checked={stage == 2}/>
                        <label for="F2" className='chatEtcMenuButton' onClick={()=>{setDrawGraph(2)}} style={{backgroundColor: '#FAEADB'}}>혜택</label>
                        
                        <input className='selected' type="radio" id="F3" name="F3" checked={stage == 3}/>
                        <label for="F3" className='chatEtcMenuButton' onClick={()=>{setDrawGraph(3)}} style={{backgroundColor: '#FEF7D9'}}>요금</label>
                        
                        <input className='selected' type="radio" id="F4" name="F4" checked={stage == 4}/>
                        <label for="F4" className='chatEtcMenuButton' onClick={()=>{setDrawGraph(4)}} style={{backgroundColor: '#E3DFEB'}}>상담/절차</label>

                        <input className='selected' type="radio" id="F5" name="F5" checked={stage == 5}/>
                        <label for="F5" className='chatEtcMenuButton' onClick={()=>{setDrawGraph(5)}} style={{backgroundColor: '#DEEDF2'}}>개통/조건</label>
                        
                        <input className='selected' type="radio" id="F6" name="F6" checked={stage == 6}/>
                        <label for="F6" className='chatEtcMenuButton' onClick={()=>{setDrawGraph(6)}} style={{backgroundColor: '#E2C3A7'}}>배송</label>

                        <input className='selected' type="radio" id="F7" name="F7" checked={stage == 7}/>
                        <label for="F7" className='chatEtcMenuButton' onClick={()=>{setDrawGraph(7)}} style={{backgroundColor: '#FFFFFF'}}>기타</label>
                    </div>
                </div>
                <div className="sentimentSub sentimentUl" id="NS-1" style={{margin: 0, gridColumn: '1/3'}}>
                    <div className="textLeft">닉네임</div>
                    <div className="textLeft">문의 내용</div>
                    <div className="textRight">시간</div>
                    <div className="textCenter">분류</div>
                </div>
            </div>
            <ChatPrint roomId={roomId} messages={msg} isSort={2} isResolve={isResolve} categoryType={1}/>
        </div>
        <div className='download' onClick={download}>문의 분류 엑셀로 내려받기</div>
        </>
    )
}

export const ChatConfirm = ({roomId}) => {
    const {messages} = useContext(MessagesContext)
    
    const [confirmCount, setConfirmCount] = useState(0)
    const [period, setPeriod] = useState(0)
    const [confirmMessages, setConfirmMessages] = useState([])
    const [deduplication, setDeDuplication] = useState(true)
    
    const [regex, setRegex] = useState('\\d{10}')
    const [modify, setModify] = useState(0)
    const [rule, setRule] = useState('')

    const [filterList, setFilterList] = useState([])
    const [filterId, setFilterId] = useState(1)

    const cnt = [-1, 5, 10, 30]

    const [currentTime, setCurrentTime] = useState(new Date());

    useEffect(() => {
        async function getFilter() {
            let newFilterList = await apiFetch(`/${roomId}/confirms/rule`)
            setFilterList(()=>newFilterList)
            //console.log(newFilterList, newFilterList.length)
            if(newFilterList.length == 0) {
                await addFilter()
                newFilterList = await apiFetch(`/${roomId}/confirms/rule`)
            }
            //console.log(filterList, newFilterList)
            const status = await apiFetch(`/${roomId}/confirms/${newFilterList[0].id}?minutes_ago=-1&deduplication=${deduplication}`)
            setConfirmCount(status.length)
            getConfirmMessage(roomId, newFilterList[0].id, -1, deduplication)
            setFilterId(()=>newFilterList[0].id)
            setRegex(()=>newFilterList[0].filter_rule)
            if(newFilterList[0].filter_example != undefined) {
                setRule(newFilterList[0].filter_example)
                setModify(1)
            } else {
                setModify(0)
            }
        }
        
        // 1초마다 시간을 업데이트하는 interval 설정
        const intervalId = setInterval(() => {
        setCurrentTime(new Date());
        }, 1000);

        getFilter()

        // 컴포넌트가 언마운트될 때 interval을 정리
        return () => clearInterval(intervalId);
    }, []);

    useEffect(()=>{
        if(messages != undefined && messages.length > 0 && confirmMessages.length > 0 && messages[0].id != confirmMessages[0].id && !messages[0].is_manager && messages[0].is_confirm){
            if(messages[0].message.confirm_msg != ""){
                setConfirmMessages((prev)=>{
                    return [messages[0], ...prev]
                })
            } else {
                console.log(messages[0])
                messages[0].message.confirm_msg = new RegExp(regex).exec(messages[0].message)[0]
                setConfirmMessages((prev)=>{
                    return [messages[0], ...prev]
                })
            }
            setConfirmCount((prev)=>++prev)
        }
    },[messages])

    const setConfirm = async (number, newDeDu) =>{
        setPeriod(number)
        setDeDuplication(newDeDu)
        getConfirmMessage(roomId, filterId, cnt[number], newDeDu)
        if(newDeDu != deduplication) {
            const tmpMessages = await apiFetch(`/${roomId}/confirms/${filterId}?minutes_ago=-1&deduplication=${newDeDu}`)
            setConfirmCount(tmpMessages.length)
        }
    }

    const setTime = (cur,time) => {
        const sec = ((new Date(cur).getTime() - new Date(time).getTime())/1000).toFixed(0);
        return (
            sec < 60 ? sec + '초전' :
            sec < 3600 ? (sec/60).toFixed(0) + '분전' :
            sec < 3600*24 ? (sec/3600).toFixed(0) + '시간전':
            (sec/3600/24).toFixed(0) + '일전'
        )
    }

    const getConfirmMessage = async (roomId, filterId, minutesAgo, deduplication) => {
        const newMsg = await apiFetch(`/${roomId}/confirms/${filterId}?minutes_ago=${minutesAgo}&deduplication=${deduplication}`)
        const newRegex = await apiFetch(`/${roomId}/confirms/rule`)
        const selectedFilter = newRegex.find(filter => filter.id === filterId);
        if (selectedFilter) {
            setRegex(()=>selectedFilter.filter_rule);
        }
        if(newMsg != null) {
            const updatedMsg = newMsg.map(msg => {
                console.log(msg, regex, selectedFilter.filter_rule)
                if ((msg.confirm_msg == '' || msg.confirm_msg == null) && new RegExp(selectedFilter.filter_rule).exec(msg.message) != null) {
                    return { ...msg, confirm_msg: new RegExp(selectedFilter.filter_rule).exec(msg.message)[0] }; // 새로운 객체 반환
                }
                return msg;
            });
            setConfirmMessages(updatedMsg)
            setConfirmCount(updatedMsg.length)
        }
    }

    const download = async () =>{
        await downloadFile(`/${roomId}/confirms/${filterId}/download?minutes_ago=${cnt[period]}&deduplication=${deduplication}`)
    }
    
    const textModify = async () => {
        if(modify == 1) {
            setModify(0)
        } else if(rule != '') {
            setModify(1-modify)
            if(modify == 0) {
                await apiFetch(`/${roomId}/confirms/rule/${filterId}?example=${rule}`, [], 'PUT')
                getConfirmMessage(roomId, filterId, cnt[period], deduplication)
                const newMsg2 = await apiFetch(`/${roomId}/confirms/${filterId}?minutes_ago=-1&deduplication=${deduplication}`)
                setConfirmCount(newMsg2.length)
                const newFilterList = await apiFetch(`/${roomId}/confirms/rule`)
                setFilterList(newFilterList)
                const selectedFilter = newFilterList.find(filter => filter.id === filterId);
                if (selectedFilter) {
                    setRegex(selectedFilter.filter_rule);
                }
            }
        }
    }

    const changeFilter = (idx) => {
        setRegex(filterList[idx].filter_rule)
        setRule(filterList[idx].filter_example == null ? '' : filterList[idx].filter_example)
        setFilterId(filterList[idx].id)

        getConfirmMessage(roomId, filterList[idx].id, cnt[period], deduplication)
    }

    const addFilter = async () => {
        if(filterList.length == 0 || filterList[filterList.length-1].filter_example != null) {
            const tmp = await apiFetch(`/${roomId}/confirms/rule/`, {'room_id': roomId}, 'POST')
            const newFilterList = await apiFetch(`/${roomId}/confirms/rule`)
            setFilterList(newFilterList)
        }
    }

    return (
        <>
        <div className="sub active" id="confirm" style={{maxHeight: "calc(100% - 191px)"}}>
            <div className='sentimentUl' style={{height:"191px", width: '100%'}}>
            <div className="chatEtcMenu">
                <div className="textLeft" id="buyCheck">
                    <div className='chatEtcMenuTitle'>전체 구매인증 수</div>
                    <div className='chatEtcMenuContent chatEtcMenuContentSingle'>{confirmCount}개</div>
                </div>
                <div className="textLeft" id="buyCheckPeriod">
                    <div className='chatEtcMenuTitle'>기간 내 구매인증 수</div>
                    <div className='chatEtcMenuContent chatEtcMenuContentSingle'>{confirmMessages.length}개</div>
                </div>
                <div className="textLeft" id="searchPeriod">
                    <div className='chatEtcMenuTitle'>조회 기간</div>
                    <div className='chatEtcMenuContent'>
                        <input className='selected' type="radio" id="periodAll" name="periodAll" checked={period==0}/>
                        <label for="periodAll" className='chatEtcMenuButton' onClick={()=>{setConfirm(0,deduplication)}}>전체</label>
                        <input className='selected' type="radio" id="period5" name="period5" checked={period==1}/>
                        <label for="period5" className='chatEtcMenuButton' onClick={()=>{setConfirm(1,deduplication)}}>5분 전</label>
                        <input className='selected' type="radio" id="period10" name="period10" checked={period==2}/>
                        <label for="period10" className='chatEtcMenuButton' onClick={()=>{setConfirm(2,deduplication)}}>10분 전</label>
                        <input className='selected' type="radio" id="period30" name="period30" checked={period==3}/>
                        <label for="period30" className='chatEtcMenuButton' onClick={()=>{setConfirm(3, deduplication)}}>30분 전</label>
                    </div>
                </div>
                <div className="textLeft" id="deleteDup">
                    <div className='chatEtcMenuTitle'>중복제거</div>
                    <div className='chatEtcMenuContent'>
                        <div className="checkBox">
                            <input type="checkbox" id="isDup" name="isDup" checked={deduplication}/>
                            <label for="isDup" onClick={()=>{setConfirm(period, !deduplication)}}></label>
                        </div>
                    </div>
                </div>
                <div className="textLeft" id="filterNav">
                    <div className='filterContainer'>
                        <div className='filterList'>
                            {filterList != [] && filterList.map((filter, idx)=>{
                                return <div className='filterItem'>
                                    <input type="radio" id={`filter${idx}`} name="filter" checked={filterId === filter.id} onChange={()=>changeFilter(idx)} style={{display: 'none'}} />
                                    <label for={`filter${idx}`}>{idx+1}</label>
                                </div>
                            })}
                            <div onClick={()=>addFilter()} className="filterItem" style={{cursor: 'pointer'}}>+</div>
                        </div>
                    </div>
                </div>
                <div className="textLeft" id="setChat" style={{width: '100vw', marginRight: '0'}}>
                    <div className='chatEtcMenuTitle'>규칙 생성용 채팅<Comment cmt="rule"/></div>
                    <div className='chatEtcMenuContent chatEtcMenuText'>
                        <input type="text" className="textData" id="textInput" placeholder="예시: 1234567890" value={rule} onChange={(e)=>{setRule(e.target.value)}} disabled={modify==0?false:true}/>
                        <div className="textDataButton" style={{color: modify ? 'rgb(134, 168, 237)' : '#818487'}} onClick={textModify}>{modify == 0 ? '확정' : '수정'}</div>
                    </div>
                </div>                
            </div>
            <div className="sentimentSub" id="confirmList" style={{width: '100vw'}}>
                <div className="textLeft">닉네임</div>
                <div className="textLeft">인증 내용</div>
                <div className="textLeft">채팅 내용</div>
                <div className="textRight">시간</div>
            </div>
            </div>
            {confirmMessages.map((chat, index) => {
                return <div class="sentimentSub" id={index}>
                            <div class="textLeft">{chat.nickname}</div>
                            <div class="textLeft">{chat.confirm_msg}</div>
                            <div class="textLeft">{chat.message}</div>
                            <div class="timeStamp textRight">{setTime(currentTime, chat.timestamp)}</div>
                        </div>
            })}
        </div>
        <div className='download' onClick={download}>리스트 엑셀로 내려받기</div>
        </>
    )
}